<template>
  <!--        创建订单-->
  <v-card>
    <v-snackbar v-model="snack.open" multi-line :light="$vuetify.theme.dark" :timeout="2000">
      {{ snack.msg }}
      <template #action="{ attrs }">
        <v-btn color="error" text v-bind="attrs" @click="snack.open = false"> 关闭 </v-btn>
      </template>
    </v-snackbar>
    <!--        地图控件-->
    <v-dialog v-model="dialogAddOrder.tmapShow" persistent max-width="700px">
      <v-card>
        <v-card-title>
          <span class="headline">选择地点</span>
        </v-card-title>
        <v-card-text>
          <v-container>
            <v-row>
              <v-col cols="9" sm="6" md="6">
                <v-text-field v-model='dialogAddOrder.positionSearch' class='user-search me-3 mb-4' dense hide-details outlined
                              placeholder='搜索地点' clearable></v-text-field>
              </v-col>
              <v-col cols="6" sm="3" md="3">
                <v-btn class='mb-4 me-3' color='info'  @click="searchPosition">
                  <span>搜索</span>
                </v-btn>
              </v-col>
            </v-row>
            已选择地点：{{ dialogAddOrder.positionAddress }} 经度：{{ dialogAddOrder.positionLng }} 纬度：{{ dialogAddOrder.positionLat }}
            <div id="tmapContainer" style="width: 650px; height: 500px">

            </div>
          </v-container>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="error" outlined
                 @click="dialogAddOrder.tmapShow = false"> 取消
          </v-btn>
          <v-btn color="success"
                 @click="submitPosition"> 确定
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-card-title>
      <span class="headline">创建订单</span>
    </v-card-title>
    <v-card-text>
      <v-container>
        <v-row>
          <v-col cols="12" sm="6" md="6">
            <v-select :items="Object.keys(orderTypes).map(key => { return { text: orderTypes[key], value: key } })"
                      v-model="dialogAddOrder.data.order_type" item-text="text" item-value="value"
                      label="用车类型" hide-details dense></v-select>
          </v-col>
          <v-col cols="12" sm="6" md="6">
            <v-select :items="Object.keys(chargeMeter).map(key => { return { text: chargeMeter[key], value: key } })"
                      v-model="dialogAddOrder.data.call_for_cost" item-text="text" item-value="value"
                      label="打表来接" hide-details dense></v-select>
          </v-col>
        </v-row>
        <v-row v-if="dialogAddOrder.data.order_type !== '1'">
          <v-col cols="12" sm="6" md="6">
            <date-picker placeholder="用车时间" v-model="dialogAddOrder.data.expected_start_at_date" type="datetime" :default-value="new Date()" :disabled-date="disabledDate" :disabled-time="disabledTime"></date-picker>
          </v-col>
          <v-col cols="6" sm="3" md="3" v-if="dialogAddOrder.data.order_type === '2'">
            <v-select :items="Object.keys(passengerNos).map(key => { return { text: passengerNos[key], value: key } })"
                      v-model="dialogAddOrder.data.passenger_no" item-text="text" item-value="value"
                      label="乘车人数" hide-details dense></v-select>
          </v-col>
          <v-col cols="6" sm="3" md="3" v-if="dialogAddOrder.data.order_type === '2'">
            <v-select :items="Object.keys(carpoolingTypes).map(key => { return { text: carpoolingTypes[key], value: key } })"
                      v-model="dialogAddOrder.data.carpooling" item-text="text" item-value="value"
                      label="用车拼车" hide-details dense></v-select>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12" sm="6" md="6">
            <v-btn color="info"
                   @click="selectAddress('start')"> 选择上车地点
            </v-btn>
          </v-col>
          <v-col cols="12" sm="6" md="6">
            <v-btn color="info"
                   @click="selectAddress('end')"> 选择目的地点
            </v-btn>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12" sm="6" md="6">
            <v-text-field v-model="dialogAddOrder.data.start_address" label="上车地点" dense disabled></v-text-field>
          </v-col>
          <v-col cols="12" sm="6" md="6">
            <v-text-field v-model="dialogAddOrder.data.end_address" label="目的地点" dense disabled></v-text-field>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="6" sm="3" md="3">
            <v-text-field v-model="dialogAddOrder.data.start_lng" label="上车经度" dense disabled></v-text-field>
          </v-col>
          <v-col cols="6" sm="3" md="3">
            <v-text-field v-model="dialogAddOrder.data.start_lat" label="上车纬度" dense disabled></v-text-field>
          </v-col>
          <v-col cols="6" sm="3" md="3">
            <v-text-field v-model="dialogAddOrder.data.end_lng" label="下车经度" dense disabled></v-text-field>
          </v-col>
          <v-col cols="6" sm="3" md="3">
            <v-text-field v-model="dialogAddOrder.data.end_lat" label="下车纬度" dense disabled></v-text-field>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="6" sm="3" md="3">
            <v-select :items="passengerList"
                      v-model="dialogAddOrder.data.user_id" item-text="real_name" item-value="id"
                      label="用车人" hide-details dense @change="passengerChange"></v-select>
          </v-col>
          <v-col cols="6" sm="3" md="3">
            <v-text-field v-model="dialogAddOrder.data.user_mobile" label="用车人手机号" dense disabled></v-text-field>
          </v-col>
          <v-col cols="4" sm="2" md="2">
            <v-text-field v-model="dialogAddOrder.data.evaluate_distance_text" label="距离" dense disabled></v-text-field>
          </v-col>
          <v-col cols="4" sm="2" md="2">
            <v-text-field v-model="dialogAddOrder.data.evaluate_price" label="车费" dense disabled></v-text-field>
          </v-col>
          <v-col cols="4" sm="2" md="2">
            <v-btn color="info" outlined @click="calcDistance" small > 计算 </v-btn>
          </v-col>
        </v-row>
      </v-container>
    </v-card-text>
    <v-card-actions>
      <v-spacer></v-spacer>
      <v-btn color="error" outlined @click="cancelSelect"> 取消
      </v-btn>
      <v-btn color="success" @click="submitSelect"> 提交
      </v-btn>
    </v-card-actions>
  </v-card>
</template>
<script>

import axios from "@axios";
import DatePicker from 'vue2-datepicker';
import 'vue2-datepicker/index.css';
import 'vue2-datepicker/locale/zh-cn';

export default {
  props: {
    message: String
  },
  components: {DatePicker},
  data() {
    return {
      snack: {
        open: false,
        msg: ''
      },
      dialogAddOrder: {
        tmapShow: false,
        positionType: "",
        positionSearch: "",
        positionAddress: "",
        positionLng: "",
        positionLat: "",
        data: {
          order_type: "1", // 订单类型：1=实时  2=回程   3=预约
          call_for_cost: "1", // 是否打表来接 1为是 2为否
          start_address: "",
          start_lng: "",
          start_lat: "",
          end_address: "",
          end_lng: "",
          end_lat: "",
          user_id: "",
          user_mobile: "",
          evaluate_distance: 0, // 最优路径距离
          evaluate_distance_text: 0,
          evaluate_duration: 0, // 预计花费时间
          evaluate_price: 0, // 最优路径估价
          expected_start_at: "",// 期望开始时间，实时、回程单为提交订单的时间，预约单为选择的时间（限制为将来的24小时内）
          expected_start_at_date: new Date(),
          estimated_arrive_at: "", // 预计到达时间，通过最优路径距离计算或者地图js库获取
          estimated_arrive_at_date: new Date(),
          desc: "",

          // 回程额外信息
          passenger_no: "1",
          carpooling: "false",
        }
      },
      orderTypes: { // 1=实时  2=回程   3=预约
        "1": '实时',
        "2": '回程',
        "3": '预约',
      },
      chargeMeter: { // 打表来接
        "1": '不',
        "2": '打表吧',
      },
      carpoolingTypes: { // 是否拼车
        "false": '独立',
        "true": '允许拼车',
      },
      passengerNos: {
        "1": "1人",
        "2": "2人",
        "3": "3人",
        "4": "4人",
      },
      tmap: undefined,
      search: new TMap.service.Search({ pageSize: 10 }),
      markers: undefined,
      passengerList: []
    }
  },
  mounted() {
    let that = this
    this.getPassengers().then(res => {
      const { code, msg } = res.data
      if (code !== 200) {
        console.log("error: ", code, msg);
        that.snack = {
          open: true,
          msg: `乘客列表请求错误，错误码：${code}, ${msg}`
        }
        return
      }
      that.passengerList = res.data.data
    }).catch(error => {
      console.log(error)
    }).finally()
  },
  methods: {
    selectAddress(type) {
      let that = this
      this.dialogAddOrder.positionType = type
      this.dialogAddOrder.tmapShow = true
      this.dialogAddOrder.positionSearch = ""
      this.dialogAddOrder.positionAddress = ""
      this.dialogAddOrder.positionLng = ""
      this.dialogAddOrder.positionLat = ""
      if(this.markers){
        this.markers.setGeometries([])
      }
      setTimeout(() => {
        const e = new TMap.LatLng(30.689358, 120.795776)
        if(!this.tmap) {
          this.tmap = new TMap.Map("tmapContainer", {
            center: e, // 设置地图中心点坐标
            zoom: 12, // 设置地图缩放级别
          })
          this.tmap.on("click",function(evt){
            const lat = evt.latLng.getLat().toFixed(7);
            const lng = evt.latLng.getLng().toFixed(7);
            console.log(lng ,lat)
            that.setPosition(lng, lat)
          })
        }
        this.search = new TMap.service.Search({ pageSize: 10 })
        this.markers = new TMap.MultiMarker({
          map: this.tmap.value,
          geometries: [],
        })
      }, 500);
    },
    searchPosition() {
      let that = this
      that.markers = new TMap.MultiMarker({
        map: this.tmap,
        geometries: [],
      })
      this.markers.setGeometries([]);
      this.search.searchRectangle({
        keyword: this.dialogAddOrder.positionSearch,
        bounds: this.tmap.getBounds(),
      }).then((result) => {
            result.data.forEach((item, index) => {
              let geometries = that.markers.getGeometries();
              geometries.push({
                id: String(index), // 点标注数据数组
                position: item.location,
              });
              that.markers.updateGeometries(geometries); // 绘制地点标注
              // that.markers.on('click', (e) => {
              //   console.log(e)
              // }); // 点击标注显示信息窗体
            });
          });
    },
    setPosition(lng, lat) {
      let that = this
      let geocoder = new TMap.service.Geocoder()
      let location = new TMap.LatLng(Number(lat), Number(lng));
      geocoder.getAddress({ location: location }) // 将给定的坐标位置转换为地址
          .then((result) => {
            that.dialogAddOrder.positionAddress = result.result.address
            that.dialogAddOrder.positionLng = lng
            that.dialogAddOrder.positionLat = lat
          });
    },
    submitPosition() {
      if(this.dialogAddOrder.positionType === 'start') {
        this.dialogAddOrder.data.start_address = this.dialogAddOrder.positionAddress
        this.dialogAddOrder.data.start_lng = this.dialogAddOrder.positionLng
        this.dialogAddOrder.data.start_lat = this.dialogAddOrder.positionLat
      }else{
        this.dialogAddOrder.data.end_address = this.dialogAddOrder.positionAddress
        this.dialogAddOrder.data.end_lng = this.dialogAddOrder.positionLng
        this.dialogAddOrder.data.end_lat = this.dialogAddOrder.positionLat
      }
      this.dialogAddOrder.tmapShow = false
    },
    cancelSelect() {
      this.dialogAddOrder.data = {
        order_type: "1",
        call_for_cost: "1",
        start_address: "",
        start_lng: "",
        start_lat: "",
        end_address: "",
        end_lng: "",
        end_lat: "",
        expected_start_at_date: new Date(),
        estimated_arrive_at_date: new Date(),
      }
      this.$emit('cancel-select', '')
    },
    submitSelect() {
      let that = this
      if(this.dialogAddOrder.data.user_id === ""){
        this.snack = {
          open: true,
          msg: `请选择用车人`
        }
        return
      }
      if(this.dialogAddOrder.data.evaluate_distance === ""){
        this.snack = {
          open: true,
          msg: `请先计算距离和车费`
        }
        return
      }
      this.addOrder().then(res => {
        const { code, msg } = res.data
        if (code !== 200) {
          console.log("error: ", code, msg);
          that.snack = {
            open: true,
            msg: `创建订单错误，错误码：${code}, ${msg}`
          }
          return
        }
        that.cancelSelect()
      }).catch(error => {
        console.log(error)
      }).finally()
    },
    passengerChange(e) {
      let that = this
      this.passengerList.forEach(function(p) {
        if(p.id === e) {
          that.dialogAddOrder.data.user_mobile = p.mobile
        }
      })
    },
    calcDistance() {
      let that = this
      if(this.dialogAddOrder.data.start_lng === '' || this.dialogAddOrder.data.start_lat === '' ||
          this.dialogAddOrder.data.end_lng === '' || this.dialogAddOrder.data.end_lat === ''){
        this.snack = {
          open: true,
          msg: `请先选择上车地点和目的地点`
        }
      }else{
        let startPosition = new TMap.LatLng(Number(this.dialogAddOrder.data.start_lat), Number(this.dialogAddOrder.data.start_lng)); // 路线规划起点
        let endPosition = new TMap.LatLng(Number(this.dialogAddOrder.data.end_lat), Number(this.dialogAddOrder.data.end_lng));
        let driving = new TMap.service.Driving({
          // 新建一个驾车路线规划类
          mp: false, // 是否返回多方案
          policy: 'PICKUP,NAV_POINT_FIRST', // 规划策略
        });
        driving.search({ from: startPosition, to: endPosition }).then((result) => {
          that.dialogAddOrder.data.evaluate_distance = result.result.routes[0].distance
          that.dialogAddOrder.data.evaluate_distance_text = (result.result.routes[0].distance / 1000) + "km"
          that.dialogAddOrder.data.evaluate_duration = result.result.routes[0].duration
          this.evaluatePrice(that.dialogAddOrder.data.evaluate_distance).then(res => {
            const { code, msg } = res.data
            if (code !== 200) {
              console.log("error: ", code, msg);
              that.snack = {
                open: true,
                msg: `计算车费错误，错误码：${code}, ${msg}`
              }
              return
            }
            that.dialogAddOrder.data.evaluate_price = Number(res.data.data).toFixed(1)
          }).catch(error => {
            console.log(error)
          }).finally()
        })
      }
    },
    disabledDate(date) {
      const today = new Date();
      today.setHours(0, 0, 0, 0);
      return date < new Date(new Date().setHours(0, 0, 0, 0)) || date > new Date(today.getTime() + 24 * 3600 * 1000)
    },
    disabledTime(date) {
      return date < new Date(new Date()) || date > new Date(new Date().getTime() + 24 * 3600 * 1000)
    },
    formatDateTime(date, format) {
      const o = {
        'M+': date.getMonth() + 1, // 月份
        'd+': date.getDate(), // 日
        'h+': date.getHours() % 12 === 0 ? 12 : date.getHours() % 12, // 小时
        'H+': date.getHours(), // 小时
        'm+': date.getMinutes(), // 分
        's+': date.getSeconds(), // 秒
        'q+': Math.floor((date.getMonth() + 3) / 3), // 季度
        S: date.getMilliseconds(), // 毫秒
        a: date.getHours() < 12 ? '上午' : '下午', // 上午/下午
        A: date.getHours() < 12 ? 'AM' : 'PM', // AM/PM
      };
      if (/(y+)/.test(format)) {
        format = format.replace(RegExp.$1, (date.getFullYear() + '').substr(4 - RegExp.$1.length));
      }
      for (let k in o) {
        if (new RegExp('(' + k + ')').test(format)) {
          format = format.replace(
              RegExp.$1,
              RegExp.$1.length === 1 ? o[k] : ('00' + o[k]).substr(('' + o[k]).length)
          );
        }
      }
      return format;
    },
    getPassengers() {
      return new Promise((resolve, reject) => {
        axios.get(`/admin-company/v1/app/user-passenger/list`, { }).then(response => resolve(response)).catch(error => reject(error))
      })
    },
    evaluatePrice(distance) {
      return new Promise((resolve, reject) => {
        axios.get(`/admin-company/v1/order/taxi/evaluate-price?distance=${distance}`, { }).then(response => resolve(response)).catch(error => reject(error))
      })
    },
    addOrder() {
      let that = this
      this.dialogAddOrder.data.estimated_arrive_at_date = new Date(this.dialogAddOrder.data.expected_start_at_date.getTime() + this.dialogAddOrder.data.evaluate_duration * 60 * 1000)
      this.dialogAddOrder.data.expected_start_at = this.formatDateTime(this.dialogAddOrder.data.expected_start_at_date, 'yyyy-MM-dd HH:mm:ss')
      this.dialogAddOrder.data.estimated_arrive_at = this.formatDateTime(this.dialogAddOrder.data.estimated_arrive_at_date, 'yyyy-MM-dd HH:mm:ss')

      this.dialogAddOrder.data.call_for_cost = Number(this.dialogAddOrder.data.call_for_cost)
      this.dialogAddOrder.data.evaluate_distance = parseInt(this.dialogAddOrder.data.evaluate_distance)

      return new Promise((resolve, reject) => {
        axios.post(`/admin-company/v1/order/taxi/commit`, that.dialogAddOrder.data).then(response => resolve(response)).catch(error => reject(error))
      })
    }
  }
}
</script>
